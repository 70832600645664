import React from "react";

export default function Index() {
  return (
    <div className="col-sm-12 col-md-7 col-lg-6 col-md-offset-2 content skills">
      <h2 className="title title-spacing">
        <span className="fa fa-stethoscope" aria-hidden="true"></span>
        <span>Scope of Practice</span>
      </h2>

      <ul>
        <li>
          <span className="bigger">General Skillset</span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Operative: Restorative and esthetic improvement via bonding
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Fixed prosthodontics: Natural tooth and implant crown and bridge
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Removable prosthodontics: Complete dentures, overdentures, partial
              dentures, and relines
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Oral and Maxillofacial Surgery: Simple and surgical extractions
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Pediatrics: Restorative, stainless-steel crowns, pulpotomies, and
              extractions
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Endodontics: Premolar and Anterior tooth treatment, expanding to
              first molar
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Periodontics: Proper diagnosis and referral for gum disease or
              grafting procedures
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">Orthodontics: Invisalign certified</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Emergency Treatment: Traumatic tooth injury management, I&amp;D,
              odontogenic infections and swelling
            </span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="bigger">
            Completed Continuing Education (updated 08/2018)
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Pediatric Trauma and Review of Cases – Course Elective while at
              UNC School of Dentistry
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Periodontology and Expanded Procedures - Course Elective while at
              UNC School of Dentistry
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              How to achieve Excellent Long-term Results with Dental Implants in
              Partially Edentulous Patients, presented by Professor Dr. Daniel
              Buser of the University of Bern, Switzerland
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Local Anesthesia – "Tips and Tricks", by Dr. Bart Johnson
            </span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="bigger">Certification and Trainings</span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Dale Carnegie Communications and Human Relations Training
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">BLS/CPR Certified</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">Annual OSHA training and Updates</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">DEA annual CE updates</span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="bigger">Professional Affiliations</span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">American Dental Association</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">North Carolina Dental Society</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">Academy of General Dentistry</span>
          </span>
        </li>
      </ul>
    </div>
  );
}

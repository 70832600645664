import React from "react";

export default function Index() {
  return (
    <div className="col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-2 col-lg-6 content about active">
      <h3 className="main-subtitle">
        I am a compassionate dentist dedicated to patient-centered care,
        continued life-long learning, and excellence in clinical skill. Please
        use my website to learn more about myself and my career.
      </h3>
      <div className="download-buttons">
        {/* <a
          href="./Megan Hayworth CV.pdf"
          target="_blank"
          className="btn btn-resume"
        >
          Download My CV
        </a> */}
        <a
          href="./Megan Hayworth Resume.pdf"
          target="_blank"
          className="btn btn-resume"
        >
          Download My Resume
        </a>
      </div>
    </div>
  );
}

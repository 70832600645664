import React from 'react';
import { NavLink } from "react-router-dom";


export default function Navbar() {
    return (
        <div className="container-fluid">
            <header className="row">

                <div className="col-sm-12 col-md-2">
                    <a href=".">
                        <img className="mesmall img-responsive img-circle center-block mesmall-tooth" src="./static/tooth.jpg" alt="Dr. Megan Hayworth, DDS" />
                    </a>
                    <a href=".">
                        <img className="mesmall img-responsive img-circle center-block mesmall-megan" src="./static/megan-photo-mini.png" alt="Dr. Megan Hayworth, DDS" />
                    </a>
                </div>

                <div className="col-sm-12 col-md-7">
                    <h1 className="myname">Megan Hayworth, DDS</h1>
                    <div className='line-break'></div>
                    <nav className="navbar navbar-default">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbar-collapse">
                            <ul className="nav navbar-nav">
                                <li><NavLink to="/">About</NavLink></li>
                                <li><NavLink to="/education">Education</NavLink></li>
                                <li><NavLink to="/work">Work Experience</NavLink></li>
                                <li><NavLink to="/skills">Scope of Practice</NavLink></li>
                                <li><NavLink to="/contact">Contact</NavLink></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}
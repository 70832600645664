import React from "react";

export default function Index() {
  return (
    <div className="col-sm-12 col-md-7 col-lg-6 col-md-offset-2 content education active">
      <h2 className="title title-spacing">
        <span className="fa fa-book" aria-hidden="true"></span>
        <span>Education</span>
      </h2>

      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>July 2018 – June 2019</span>
          </span>
        </li>

        <li>
          <span className="bigger">
            General Practice Residency (GPR) Program
            <br />
            <strong>University of Tennessee Medical Center</strong>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-map-marked-alt" aria-hidden="true"></span>
            <span>Knoxville, TN</span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>August 2014 – May 2018</span>
          </span>
        </li>

        <li>
          <span className="bigger">
            Doctor of Dental Surgery (DDS)
            <br />
            <strong>University of North Carolina at Chapel Hill</strong>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-map-marked-alt" aria-hidden="true"></span>
            <span>Chapel Hill, NC</span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>August 2009 – May 2013</span>
          </span>
        </li>
        <li>
          <span className="bigger">
            <div>
              <span>B.S. in Biology</span>
              <span className="smaller ml-1">Minor in Chemistry</span>
            </div>
            <strong>University of North Carolina at Chapel Hill</strong>
          </span>
        </li>

        <li>
          <span className="smaller">
            <span className="fa fa-map-marked-alt" aria-hidden="true"></span>
            <span>Chapel Hill, NC</span>
          </span>
        </li>
      </ul>
    </div>
  );
}

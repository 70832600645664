import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "whatwg-fetch";

const siteKey = "6LdS2xQUAAAAAOG3Bl_1cARlqkNko1JH07kvpDte";

export default class Index extends Component {
  initState = {
    name: "",
    email: "",
    message: "",
    recaptcha: "",
  };
  state = {
    form: { ...this.initState },
    response: "",
    recaptchaRef: React.createRef(),
  };

  setRecaptchaRef = (recaptchaRef) => this.setState({ recaptchaRef });
  onRecaptchaChange = (recaptcha) =>
    this.setState({ form: { ...this.state.form, recaptcha } });
  inputChange = ({ target: { name, value } }) =>
    this.setState({ form: { ...this.state.form, [name]: value } });

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ response: "" });

    if (!this.state.form.recaptcha) {
      this.setState({ response: "Please fill out the captcha." });
      return;
    }

    fetch("/email/contact", {
      method: "POST",
      body: JSON.stringify({ ...this.state.form, emailTo: "megan" }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.setState({
          response: "Message sent successfully!",
          form: { ...this.initState },
        });
        this.state.recaptchaRef.reset();
      })
      .catch((error) => {
        this.state.recaptchaRef.reset();
        this.setState({
          response: "There was an error in sending your message.",
          form: { recaptcha: "" },
        });
      });
  };

  render() {
    return (
      <div className="col-sm-12 col-md-7 col-lg-6 col-md-offset-2 content contact active">
        <h2 className="title">
          <span className="fa fa-globe" aria-hidden="true"></span>
          <span>Contact Information</span>
        </h2>
        <p className="subtitle">
          If you have a question or would like to reach out, you may write me
          via the contact form below. Please allow 24-48 hours for a response.
        </p>

        <div role="form" lang="en-US" dir="ltr">
          <form onSubmit={this.onSubmit}>
            <div className="field">
              <input
                type="text"
                name="name"
                value={this.state.form.name}
                onChange={this.inputChange}
                size="40"
                aria-invalid="false"
                placeholder="Your name"
                required
              />
            </div>

            <div className="field">
              <input
                type="email"
                name="email"
                value={this.state.form.email}
                onChange={this.inputChange}
                size="40"
                aria-invalid="false"
                placeholder="Your email address"
                required
              />
            </div>

            <div className="field">
              <textarea
                name="message"
                cols="40"
                rows="10"
                value={this.state.form.message}
                onChange={this.inputChange}
                aria-required="true"
                aria-invalid="false"
                required
              ></textarea>
            </div>

            <div className="field">
              <div className="row">
                <div className="col-sm-12 col-md-6 text-xs-center">
                  <ReCAPTCHA
                    sitekey={siteKey}
                    ref={this.setRecaptchaRef}
                    onChange={this.onRecaptchaChange}
                  />
                  ,
                </div>

                <div className="col-sm-12 col-md-6 contact-button-wrap">
                  <button
                    type="submit"
                    className="btn send pull-right"
                    id="submit-contact"
                  >
                    Send
                  </button>
                  <span id="sending-contact" className="hidden">
                    <i className="fas fa-spinner fa-spin fa-2x"></i>
                  </span>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="row">
                <div className="col-sm-12 text-center">
                  {this.state.response}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { AnimatePresence } from "framer-motion";
import { Route, Routes, BrowserRouter, useLocation, Navigate } from "react-router-dom";

import Navbar from './navbar';
import Content from './content';
import Motion from './motion';

import Home from './pages/home';
import Education from './pages/education';
import Skills from './pages/skills';
import Work from './pages/work';
import Contact from './pages/contact';

import './style/app.scss';


const AnimatedRoutes  = () => {
    const location = useLocation();

    return (
            <div>
                <Navbar />
                    <Content>
                        <AnimatePresence exitBeforeEnter>
                            <Routes location={location} key={location.pathname}>
                                <Route path="/education" element={<Motion><Education/></Motion>} />
                                <Route path="/work" element={<Motion><Work/></Motion>} />
                                <Route path="/skills" element={<Motion><Skills/></Motion>} />
                                <Route path="/contact" element={<Motion><Contact/></Motion>} />
                                <Route path="/" element={<Motion><Home/></Motion>} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </AnimatePresence>
                    </Content>
                <div className='side-image' alt='Dr. Megan Hayworth, DDS'></div>
            </div>
    )
};



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter><AnimatedRoutes /></BrowserRouter>);
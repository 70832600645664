import React from "react";

export default function Index() {
  return (
    <div className="col-sm-12 col-md-7 col-lg-6 col-md-offset-2 content work active">
      <h2 className="title title-spacing">
        <span className="fa fa-briefcase" aria-hidden="true"></span>
        <span>Work Experience</span>
      </h2>

      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>August 2021 - Now</span>
          </span>
        </li>
        <li>
          <span className="bigger">
            <div>General Dentist</div>
            <strong>
              Brier Creek Dental - Raleigh, NC Area
            </strong>
          </span>
        </li>

        <li className="smaller-list-item">
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
            General dentist in three doctor practice.
            </span>
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
            Complete same-day crown appointments with use of the iTero scanner and Glidewell fastmill.io.
            </span>
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Procedures completed listed at top of CV as 'procedure interests.'
            </span>
          </span>
        </li>

      </ul>


      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>August 2019 - July 2021</span>
          </span>
        </li>
        <li>
          <span className="bigger">
            <div>General Dentist</div>
            <strong>
              Lane &amp; Associates Family Dentistry - Raleigh, NC Area
            </strong>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="smaller">
            <span className="fa fa-clock" aria-hidden="true"></span>
            <span>July 2018 - July 2019</span>
          </span>
        </li>
        <li>
          <span className="bigger">
            <div>General Practice Residency</div>
            <strong>University of Tennessee Hospital</strong>
          </span>
        </li>

        <li className="smaller-list-item">
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              1-year GPR hospital-based residency program in Knoxville, TN.
            </span>
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Expanded knowledge in dental trauma, odontogenic infections,
              extractions, implant placement/planning and endodontic procedures.
            </span>
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Patient populations treated: Insurance, FFS, VA patient care,
              special needs operating room treatment, and public health dental
              clinics.
            </span>
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Rotations: Dental Operating Room, Health Department, Oral Surgery,
              Family Medicine, General Anesthesia, and Forensic Dentistry.
            </span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="bigger">
            <div>UNC School of Dentistry</div>
            <strong>University of North Carolina at Chapel Hill</strong>
          </span>
        </li>
        <li className="smaller-list-item">
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>Salt Lake Donated Dental Services</strong>: Community
              health rotation to provide comprehensive dental services to
              patients in Salt Lake City, UT. (8/2017 – 9/2017)
            </span>

            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>Cherokee Indian Hospital</strong>: Indian Health Service
              rotation to provide comprehensive dental care to pediatric
              patients in Cherokee, NC. (July 2017)
            </span>

            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>JD Bernstein Dental Services Center</strong>: Community
              health rotation to provide preventative, restorative, and
              emergency dental services to patients in Greenville, NC. (June
              2017)
            </span>

            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>Special Needs Dentistry Experience</strong>: Completed
              three rotations to provide caregiver guidance and preventative
              treatment to special needs patients at the UNC School of
              Dentistry. (2017)
            </span>

            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>Hospital Operating Room Dentistry</strong>: Assisted
              residents and faculty in treating five pediatric and two adult
              special needs full-mouth rehabilitation cases under general
              anesthesia. (2016, 2017)
            </span>

            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              <strong>UNC-Moldova Dental Exchange Project</strong>: Exchange
              mission trip to treat orphan pediatric patients in Chisinau,
              Moldova. (March 2016)
            </span>
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span className="bigger">
            <strong>Past Community Volunteerism and Service</strong>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">CAARE Clinic – Durham, NC</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">SHAC Clinic – Chapel Hill, NC</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Samaritan Health Clinic – Durham, NC
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">Wakebrook Clinic – Raleigh, NC</span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              North Carolina Mission of Mercy Clinics – Various sites across NC
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Alaska Mission of Mercy Clinic – Anchorage, AK
            </span>
          </span>
        </li>
        <li>
          <span className="smaller">
            <span className="fa fa-tooth" aria-hidden="true"></span>
            <span className="sub-text">
              Give Kid’s a Smile Day – Chapel Hill, NC
            </span>
          </span>
        </li>
      </ul>
    </div>
  );
}
